import {useMemo} from "react";

import {useSoonestSlotByReasonSlugsQuery} from "../../../queries/useSoonestSlotByReasonSlug";
import {ExtendedCareEntrySearchResult} from ".";

export const useSoonestSlotForResult = (
  care: ExtendedCareEntrySearchResult,
  selectedLocation?: {id: string; slug: string; region: {slug: string}; specialtyIds: string[]},
  selectedRegion?: string,
) => {
  const isTopic = !care.appointment_reason;
  const slugsToCheck = useMemo(
    () => (isTopic ? care.cares?.map(care => care.slug).slice(0, 4) || [] : [care.slug]),
    [isTopic, care.cares, care.slug],
  );

  return useSoonestSlotByReasonSlugsQuery(slugsToCheck, selectedLocation, selectedRegion);
};
